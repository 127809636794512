import { useState, useEffect, useMemo } from 'react';
import styles from './style.module.css'
import axios from 'axios';
import { URL } from '../../Settings';
import moment from 'moment';

function parseXML(data) {
    const tempData = data.IsraelCitiesWeatherForecastEvening || data.IsraelCitiesWeatherForecastMorning;
    const location = tempData?.Location.find(item => item.LocationMetaData.LocationId == 402)
    const tempToday = location.LocationData.TimeUnitData[0];

    if (!tempToday.Element[0]) {
        const a = tempToday.Element;
        tempToday.Element = [];
        tempToday.Element.push(a)
    }
    const tempObj = {
        minTemp: tempToday?.Element?.find(item => item.ElementName == 'Minimum temperature')?.ElementValue || tempToday.Element.ElementValue || null,//temp?.Element[3]?.ElementValue || null,
        maxTemp: tempToday?.Element?.find(item => item.ElementName == 'Maximum temperature')?.ElementValue || null//temp?.Element[1]?.ElementValue|| null
    }
    return tempObj;
}


export default function Time({ settings, building }) {

    const [weather, setWeather] = useState(null)
    const [date1, setDate] = useState(moment())

    const { date, time } = useMemo(() => {

        const today = date1.toDate();
        const day = today.toLocaleDateString(locale, { weekday: 'long' });
        const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

        const hour = today.getHours();
        const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;

        const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: false, minute: 'numeric' });
        return {
            date,
            time,
            wish,
        };
    }, [date1])

    const colorScheme = useMemo(() => settings?.colorScheme ? settings?.colorScheme : 'blue', [settings])
    useEffect(() => {
        if (building) {
            GetLocation();
            
        }
        setInterval(() => {
            setDate(moment());
        }, 60000);
    }, [building])

    function GetLocation() {
        if (building.Latitude !== 0 && building.Longitude !== 0) {
            localStorage.setItem("location", JSON.stringify({ latitude: building.Latitude, longitude: building.Longitude }));
            GetWeather();
            setInterval(() => {
                GetWeather();
            }, 60 * 60 * 1000)
        } else {
            axios.get(`https://geolocation-db.com/json/`).then(res => {
                if (res.status == 200) {
                    localStorage.setItem("location", JSON.stringify(res.data));
                    GetWeather();
                    setInterval(() => {
                        GetWeather();
                    }, 60 * 60 * 1000)
                }
            }).catch(error => console.log(error))
        }

    }
    function GetWeather() {
        if (localStorage.getItem("location")) {
            var location = JSON.parse(localStorage.getItem("location"));
            if (location) {
                axios.get(`https://api.open-meteo.com/v1/forecast?latitude=${location.latitude}&longitude=${location.longitude}&current=temperature&timezone=Asia/Jerusalem`,).then(res => {
                    if (res.status == 200)
                        setWeather(res.data);
                }).catch(error => console.log(error))
            } else {
                GetLocation();
            }
        } else {
            GetLocation();
        }
    }
    return (
        <div className={`${styles.container} color-scheme-${colorScheme} color-scheme-${colorScheme}-gradient`}>
            <div className={styles.time} style={{ textAlign: 'center', fontSize: settings?.dateTimeFontSize }}>
                {time}
            </div>
            <div className={styles.date} style={{ textAlign: 'center', fontSize: settings?.dateDateFontSize }}>
                {date}
            </div >
            <div className={styles.weather}>
                <div className={`${styles.weather_temp} d-flex`} style={{ fontSize: settings?.dateWeatherFontSize }}>
                    {/* {weather?.maxTemp && <div>{weather?.maxTemp}<sup>°</sup> - </div>} */}
                    {weather?.current?.temperature && <div>{weather.current.temperature}<sup>°</sup></div>}
                </div >
                <div className={styles.text} style={{ fontSize: settings?.dateWeatherFontSize }} >
                    המעלות בחוץ:
                </div >
            </div >
        </div>
    )
}
const locale = 'he';
