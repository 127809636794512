import styles from './style.module.css'
import Logo from '../../assets/nuvola_logo.gif'
export default function Header({ settings, data, building, logoClick }) {

    return (
        <div className={styles.header_box}>
            <div className={styles.text_container} >
                <h2 className={styles.heading} style={{ fontSize: data?.TextSizeFloorNameInFloor }}>{data?.FloorNameToFloor}<img style={{ width: `${settings?.headerImageLogoWidth}%`, marginLeft: '10px', marginRight: '10px' }} src={building?.LogoBuilding} /></h2>
            </div >
            <div onClick={() => logoClick()} className={styles.logo_container}>
                <img className={styles.logo} src={Logo} />
            </div>
        </div >

    )
}