
import { useMemo, useEffect } from 'react'
import './style.css'
import Header from '../Header'
import Time from '../Time'
import SidebarImage from '../SidebarImage'
import NoticeBoard from '../NoticeBoard'
import HeaderMessages from '../HeaderMessages'
import MediaSlider from '../MediaSlider'
import Headlines from '../Headlines'
import MediaPlayer from '../MediaPlayer'
import News from '../News'
import Audio from '../Audio'
import { useState } from 'react'

export default function Layout({ settings, data, messages, building, media, ads, floorId }) {
    const [hideSidebarImage, setHideSidebarImage] = useState(true)
    const colorScheme = useMemo(() => settings?.colorScheme ? settings?.colorScheme : 'blue', [settings])
    const [mute, setMute] = useState(false);
    const [playingManually, setPlayingManually] = useState(false);

    useEffect(() => {
        setMute(MuteNow());
        setInterval(() => {
            setMute(MuteNow());
        }, 15 * 60 * 1000)
    }, [settings])

    function MuteNow() {
        const isMute = (shabbatNow() && settings?.muteShabbat) || (nightNow() && settings?.muteNight) || settings?.mute;
        console.log(`Background music mute? ${isMute}`)
        return isMute;
    }
    function shabbatNow() {
        var today = new Date();
        var hour = today.getHours();
        var todayNum = today.getDay();
        var nowShabbat = (todayNum == 5 && hour >= settings?.muteShabbatFrom) || (todayNum == 6 && hour < settings.muteShabbatTo)
        return nowShabbat;
    }

    function nightNow() {
        const today = new Date();
        var hour = today.getHours();
        var nowNight = (hour >= settings?.muteNightFrom || hour < settings.muteNightTo)
        return nowNight;
    }
    function logoClick() {
        if (playingManually === false && mute === false) {
            setMute(true);
            setPlayingManually(true);
            setTimeout(() => setMute(false), [2000])
        }


    }

    return (
        <>
            <div className="row g-0 header-container-wrapper">
                <div className={`g-0 ${settings?.headerUpClass}`}>
                    <Header logoClick={logoClick} settings={settings} data={data} building={building} />
                </div>
                <div className={settings?.dateUpClass}>
                    <Time settings={settings} rssItems={null} building={building} />
                </div>
            </div>
            <div className="row g-0 container-height body-container-wrapper">
                {settings?.sidebarRight &&
                    <div className={`custom-gap move-up ${settings?.sidebarRightClass}`}>
                        {media.filter(x => x.Title == '2').length > 0 && settings?.imageExpandedRight &&
                            <div className="right-sidebar-horz-slider"
                                style={{ maxHeight: `${settings?.imageExpandedRightHeight}px`, flex: `0 0 ${settings?.imageExpandedRightHeight}px` }}>
                                <SidebarImage media={media.filter(x => x.Title == '2')} />
                            </div>
                        }
                        {settings?.messageMainRight &&
                            <div className={`right-sidebar-chat-box ${(hideSidebarImage || (!settings?.imageExpandedRight)) && 'sidebar-image-hidden'}`}
                                style={{
                                    maxHeight: `${((!hideSidebarImage) && settings?.imageExpandedRight) ? `calc(100% - ${settings.imageExpandedRightHeight}px)` : '100%'}`,
                                    flex: `${(!hideSidebarImage) && settings?.imageExpandedRight ? `0 0 calc(100% - ${settings?.imageExpandedRightHeight}px)` : '100%'}`
                                }}>
                                <NoticeBoard msgs={messages.filter((x) => x.Title == '1')} settings={settings} />
                            </div >
                        }
                    </div>
                }
                {settings?.sidebarMiddle &&
                    <div className={`custom-gap ${settings.sidebarMiddleClass} h-100`}>
                        {settings.messageExpanded &&
                            <div className="msg-section-move-up" style={{ width: settings.messageExpandedWidth || '70%' }}>
                                <HeaderMessages settings={settings} msgs={messages.filter((x) => x.Title == '2')} />
                            </div >
                        }
                        <div className='center-media-container h-100'>
                            {/* <MediaSlider type={1} media={media.filter(x => x.Title == '1')} /> */}
                            <MediaPlayer building={building} ads={ads.filter(x => x.Title == '1')} type={1} media={media.filter(x => x.Title == '1')} floorId={floorId} />
                        </div>
                    </div >
                }
                {settings?.sidebarMiddle2 &&
                    <div className={`custom-gap h-100 ${settings.sidebarMiddle2Class}`}>
                        <div className='h-100' style={{ padding: '15px', backgroundColor: 'white' }}>
                            <MediaSlider type={2} media={media.filter(x => x.Title == '2')} />

                        </div>
                    </div>
                }
                {settings?.sidebarLeft &&
                    <div className={`custom-gap h-100 ${settings.sidebarLeftClass}`}>
                        <News settings={settings} building={building} />
                    </div>
                }
            </div >
            {settings?.rssBottom &&
                <div className={`row  g-0 footer-container-wrapper color-scheme-${colorScheme}-headline`}>
                    <div className="col-12">
                        <Headlines settings={settings} />
                    </div>
                </div>
            }
            {mute === false &&
                <div style={{ visibility: 'hidden' }}>
                    {building?.Song ? (<Audio settings={settings} media={[building.Song]} />) :
                        (<MediaSlider type={3} media={media.filter(x => x.Title == '3').length === 1 ? [...media.filter(x => x.Title == '3'), ...media.filter(x => x.Title == '3')] : media.filter(x => x.Title == '3')} />)
                    }
                </div>
            }
        </>
    )
}