import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    BrowserRouter,
    Route,
    Routes,
    Link,
    useParams
} from "react-router-dom";
import './index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
    apiKey: '6694bcd20ea0bc26318a723c2cdb70c2',
    plugins: [new BugsnagPluginReact()],
})
BugsnagPerformance.start('6694bcd20ea0bc26318a723c2cdb70c2')
const root = ReactDOM.createRoot(document.getElementById('root'));

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const ErrorView = () =>
    <div>
        <p>An error occured</p>
    </div>
root.render(
    <ErrorBoundary FallbackComponent={ErrorView}>
        <BrowserRouter>
            <Routes>
                <Route path="/:buildingId?/:floorNumber?/:prod?" Component={App} />
            </Routes>
        </BrowserRouter>
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
