import { useEffect, useMemo, useState } from 'react';
import styles from './style.module.css'
import { useParams } from 'react-router-dom';
import { URL } from '../../Settings';
import axios from 'axios';

let startMedia = true;
let currentMedia = -1;
let currentAd = -1;
let mediaCount = 0;
let adCount = 0;
// const adAntecedent = 3;
// const adConsequent = 1;
let timeout;
const sourceUrl = "https://api.view.nuvola.co.il/"
export default function MediaPlayer({ type, ads, media, building, floorId }) {
    const mute = useMemo(() => true, []);
    const { buildingId } = useParams();
    const { AdAntecedent, AdConsequent } = building ? building : { AdConsequent: 1, AdAntecedent: 5 }


    useEffect(() => {
        PlayNext();
    }, [media])

    function PlayNext() {

        //console.log("Media", mediaCount, "Ads", adCount)
        clearTimeout(timeout);
        if (media.length === 0 && ads.length === 0)
            return;
        else if ((ads.length == 0 || AdConsequent <= 0) && media.length > 0) {
            StartNextMedia();
        } else if (AdAntecedent > 0 && mediaCount < AdAntecedent && media.length > 0 && mediaCount > 0) {
            StartNextMedia();
        } else if (AdConsequent > 0 && adCount < AdConsequent && ads.length > 0 && adCount > 0) {
            StartNextAd();
        } else if (AdAntecedent == mediaCount && ads.length > 0) {
            mediaCount = 0;
            StartNextAd();
        } else if (AdConsequent == adCount && media.length > 0) {
            adCount = 0
            StartNextMedia();
        } else if (mediaCount == 0 && adCount == 0 && AdAntecedent > 0 && media.length > 0) {
            StartNextMedia();
        } else if (mediaCount == 0 && adCount == 0 && AdConsequent > 0 && ads.length > 0) {
            StartNextAd();
        } else {
            //console.log("play nothing")
        }
    }


    function StartNextMedia() {
        if (media.length > 0) {

            StopCurrent();
            if (currentMedia === -1 || media.length === (currentMedia + 1))
                currentMedia = 0;
            else if (media.length > (currentMedia + 1))
                currentMedia = currentMedia + 1;

            let element = media[currentMedia]
            const id = `media-${type}-${currentMedia}`
            element.duration = element.Duration || "00:00:10";
            element.format = element.Type == 1 ? "image" : (element.Type == 4 ? "video" : "youtube");


            const durArr = element.duration.split(':');
            var durInSecs = (Number(durArr[0]) * 60 * 60 + Number(durArr[1]) * 60 + Number(durArr[2]));
            var durInMilliSecs = durInSecs * 1000;
            const currentEle = document.getElementById(id);
            if (currentEle)
                currentEle.style.display = "unset";

            if (startMedia && element.format == "youtube") {
                startMedia = false;
                timeout = window.setTimeout(function () {
                    Play(id);
                }, 2000);
            } else {
                Play(id);
            }

            timeout = window.setTimeout(function () {
                mediaCount = mediaCount + 1;
                PlayNext();
            }, durInMilliSecs);
        }
    }
    function StartNextAd() {
        if (ads.length > 0) {

            StopCurrent();
            if (currentAd === -1 || ads.length === (currentAd + 1))
                currentAd = 0;
            else if (ads.length > (currentAd + 1))
                currentAd = currentAd + 1;

            let element = ads[currentAd]
            const id = `media-ads-${type}-${currentAd}`
            element.duration = element.Duration || "00:00:10";
            element.format = element.Type == 1 ? "image" : (element.Type == 4 ? "video" : "youtube");

            const durArr = element.duration.split(':');
            var durInSecs = (Number(durArr[0]) * 60 * 60 + Number(durArr[1]) * 60 + Number(durArr[2]));
            var durInMilliSecs = durInSecs * 1000;
            const currentEle = document.getElementById(id);
            if (currentEle)
                currentEle.style.display = "unset";

            if (startMedia && element.format == "youtube") {
                startMedia = false;
                timeout = window.setTimeout(function () {
                    Play(id);
                }, 2000);
            } else {
                Play(id);
            }

            timeout = window.setTimeout(function () {
                adCount = adCount + 1;
                UpdateAdCount(element.Id, durInSecs);
                PlayNext();
            }, durInMilliSecs);
        }
    }
    function UpdateAdCount(id, duration) {
        axios.post(`${sourceUrl}/api/ads/count?adId=${id}&floorId=${floorId.current}&seconds=${duration}`, null, { headers: { Secret: 'Maxim1221' } }).then(res => {
            //if (res.data === "Succeeded")
            //console.log(`Updated ad count with Id ${id}, Seconds ${duration}`)
        })
    }


    function StopCurrent() {
        Stop(`media-${type}-${currentMedia}`)
        Stop(`media-ads-${type}-${currentAd}`)
    }

    function resizePlayer(ratio) {

        var win = document.getElementById(`media-${type}`);
        if (!win)
            return 0;
        var width = win.clientWidth,
            playerWidth,
            height = win.clientHeight,
            playerHeight,
            ratio = ratio || 16 / 9;
        var iframs = win.getElementsByTagName("iframe");


        if (width / ratio < height) {
            playerWidth = Math.ceil(height * ratio);
            return playerWidth;
        } else {
            return '100%'
        }
    }
    function postMessageToPlayer(player, command) {
        if (player == null || command == null) return;
        player.contentWindow.postMessage(JSON.stringify(command), "*")

    }

    function Stop(currentId) {
        const currentEle = document.getElementById(currentId);
        if (currentEle) {
            currentEle.style.display = "none";
            //console.log(currentId, 'stop')
            if (currentEle.nodeName === "VIDEO") {
                currentEle.pause();
                currentEle.currentTime = 0;
            } else if (currentEle.nodeName === "IFRAME") {
                postMessageToPlayer(currentEle, {
                    "event": "command",
                    "func": "stopVideo"
                });
            }
        }
    }
    function Play(currentId) {
        const currentEle = document.getElementById(currentId);
        if (currentEle) {
            //console.log(currentId, 'play')
            currentEle.style.display = "unset";
            if (currentEle.nodeName === "VIDEO") {
                currentEle.play();
            } else if (currentEle.nodeName === "IFRAME") {
                postMessageToPlayer(currentEle, {
                    "event": "command",
                    "func": "mute"
                });
                postMessageToPlayer(currentEle, {
                    "event": "command",
                    "func": "playVideo"
                });
                if (!mute) {
                    postMessageToPlayer(currentEle, {
                        "event": "command",
                        "func": "unMute"
                    });
                }
            }
        }
    }
    return (
        <div id={`media-${type}`} className={styles.media_slides}>
            {media.map((element, i) => {
                const id = `media-${type}-${i}`
                element.href = element.Value || null;
                element.format = element.Type == 1 ? "image" : (element.Type == 4 ? "video" : "youtube");

                if (element.format == "youtube") {
                    var href = element.href.split('&');
                    element.href = href[0];
                }
                if (element.format == "image") {
                    return <img key={i} style={{ display: 'none' }} className={styles.img} id={id} src={`${sourceUrl}Resources/element_image/${buildingId}/${element.href}`} />
                }
                else if (element.format == "video") {
                    return <video key={i} id={id} style={{ width: 'auto', objectFit: "cover", display: 'none', height: '100%' }} preload="auto" muted={mute} loop><source src={element.href} type="video/mp4" /></video>
                }
                return <iframe key={i} id={id} style={{ display: 'none', height: '100%', minWidth: resizePlayer(16 / 9) }} className='iframe_youtube embed-player slide-media' src={`${element.href}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&start=1`} allow="autoplay"></iframe>

            })}
            {ads.map((element, i) => {
                const id = `media-ads-${type}-${i}`
                element.href = element.Value || null;
                element.format = element.Type == 1 ? "image" : (element.Type == 4 ? "video" : "youtube");

                if (element.format == "youtube") {
                    var href = element.href.split('&');
                    element.href = href[0];
                }
                if (element.format == "image") {
                    return <img key={i} style={{ display: 'none' }} className={styles.img} id={id} src={`${sourceUrl}Resources/element_image/ads/${element.href}`} />
                }
                else if (element.format == "video") {
                    return <video key={i} id={id} style={{ width: 'auto', objectFit: "cover", display: 'none', height: '100%' }} preload="auto" muted={mute} loop><source src={element.href} type="video/mp4" /></video>
                }
                return <iframe key={i} id={id} style={{ display: 'none', height: '100%', minWidth: resizePlayer(16 / 9) }} className='iframe_youtube embed-player slide-media' src={`${element.href}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&start=1`} allow="autoplay"></iframe>

            })}
        </div>
    )
}



// const ads = [

//     {
//         "Id": 24569,
//         "Title": "1",
//         "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
//         "Value": "https://www.youtube.com/embed/GLBBo2Jihvs",
//         "DateStart": "2023-05-25T06:00:00",
//         "DateEnd": "2023-06-01T23:00:00",
//         "BuildingId": 1244,
//         "Building": null,
//         "IsActive": true,
//         "Deleted": false,
//         "Duration": "00:00:10",
//         "Type": 2,
//         "IsManager": false
//     },
//     {
//         "Id": 24569,
//         "Title": "1",
//         "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
//         "Value": "https://www.youtube.com/embed/JxIN5fruFFo",
//         "DateStart": "2023-05-25T06:00:00",
//         "DateEnd": "2023-06-01T23:00:00",
//         "BuildingId": 1244,
//         "Building": null,
//         "IsActive": true,
//         "Deleted": false,
//         "Duration": "00:00:11",
//         "Type": 2,
//         "IsManager": false
//     },

// ]
// const media = [
//     {
//         "Id": 17373,
//         "Title": "1",
//         "Name": "לשמור על הניקיון",
//         "Value": "https://f005.backblazeb2.com/file/nuvola-view-uploads/1685292873.mp4",
//         "DateStart": "2023-05-13T15:55:00",
//         "DateEnd": "2023-06-30T23:00:00",
//         "BuildingId": 1244,
//         "Building": null,
//         "IsActive": true,
//         "Deleted": false,
//         "Duration": "00:00:12",
//         "Type": 4,
//         "IsManager": false
//     },
//     {
//         "Id": 24569,
//         "Title": "1",
//         "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
//         "Value": "https://www.youtube.com/embed/apKYICJ-LTY",
//         "DateStart": "2023-05-25T06:00:00",
//         "DateEnd": "2023-06-01T23:00:00",
//         "BuildingId": 1244,
//         "Building": null,
//         "IsActive": true,
//         "Deleted": false,
//         "Duration": "00:00:17",
//         "Type": 2,
//         "IsManager": false
//     },
//     {
//         "Id": 24569,
//         "Title": "1",
//         "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
//         "Value": "https://www.youtube.com/embed/BB49x_uMlGA",
//         "DateStart": "2023-05-25T06:00:00",
//         "DateEnd": "2023-06-01T23:00:00",
//         "BuildingId": 1244,
//         "Building": null,
//         "IsActive": true,
//         "Deleted": false,
//         "Duration": "00:00:10",
//         "Type": 2,
//         "IsManager": false
//     },
//     // {
//     //     "Id": 24569,
//     //     "Title": "1",
//     //     "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
//     //     "Value": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/2560px-Image_created_with_a_mobile_phone.png",
//     //     "DateStart": "2023-05-25T06:00:00",
//     //     "DateEnd": "2023-06-01T23:00:00",
//     //     "BuildingId": 1244,
//     //     "Building": null,
//     //     "IsActive": true,
//     //     "Deleted": false,
//     //     "Duration": "00:00:10",
//     //     "Type": 1,
//     //     "IsManager": false
//     // },
// ]

