
const Settings = {
    sidebarUp: true,
    headerUpClass: "col-10",
    dateUpClass: "col-2",
    dateTimeFontSize: 89,
    dateDateFontSize: 18,
    dateWeatherFontSize: 18,
    headerImageLogoWidth: 6,
    sidebarRight: true,
    messageMainRight: true,
    imageExpandedRight: true,
    imageExpandedRightHeight: 0,
    sidebarRightClass: "col-4",
    messageMainRightSpeed: 5000,
    messageMainRightFontSize: 20,
    messageMainRightDateFontSize: 12,
    messageMainRightFromFontSize: 18,
    sidebarMiddle: true,
    imageMain: true,
    messageExpanded: true,
    sidebarMiddleClass: "col-5",
    messageExpandedSpeed: 2000,
    messageExpandedWidth: '114%',
    sidebarMiddle2: false,
    sidebarMiddle2Class: "col-6",
    sidebarLeft: true,
    marketRssLeft: false,
    newsLeft: true,
    shabbatLeft: false,
    sidebarLeftClass: "col-3",
    newsLeftSlidesToShow: 10,
    newsLeftSpeed: 4000,
    newsLeftMainFontSize: 18,
    newsLeftSecondFontSize: 12,
    shabbatLeftMainFontSize: 27,
    shabbatLeftSecondFontSize: 20,
    rssBottom: true,
    rssBottomSpeed: 20000,
    mute: false,
    muteShabbat: true,
    muteShabbatFrom: 15,
    muteShabbatTo: 21,
    muteNight: true,
    muteNightFrom: 23,
    muteNightTo: 7,
};

const URL = {
    api: 'https://api.view.nuvola.co.il/api/Screen',
    rss: 'https://rss.view.nuvola.co.il/api/Rss',
    media: 'https://media.view.nuvola.co.il',
    management: 'https://api.view.nuvola.co.il/api/ManagementSystem'
}

export default Settings;
export { URL }