
import styles from './style.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
export default function HeaderMessages({ msgs }) {
    // const msgs = [{Text: 'Hello world testing'}, {Text: 'Hello world testing 2'}, {Text: 'Hello world testing 3'}, {Text: 'Hello world testing 4'}, {Text: 'Hello world testing 5'}]
    return (
        <div className={styles.container}>
            <div className="slick-container" style={{ height: '100%' }}>
                <Swiper
                    slidesPerView={1}
                    slidesPerGroup={1}
                    spaceBetween={5}
                    direction={'vertical'}
                    loop={msgs.length > 2}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    className={styles.swiper}
                >
                    {msgs.map((msg, i) => (
                        <SwiperSlide key={i} className={styles.swiper_slide}>
                            <div className={styles.inliine_content} dangerouslySetInnerHTML={{ __html: msg.Text }}></div>
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div >
        </div >
    )
}