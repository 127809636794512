import { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import styles from './style.module.css'
import Logo from '../../assets/wallaLogo.jpg'
import Ticker from 'react-ticker'
import axios from 'axios';
import { URL } from '../../Settings'

function parseXML(data) {
    let arr = [];
    const items = data.rss.channel.item;
    for (const item of items) {
        if (item.title && item.title != '') {
            var time1 = new Date(item.pubDate['#cdata-section']);
            time1.setHours(time1.getHours() - 1);
            arr.push({
                title: item.title['#cdata-section'],
                time: time1
            });
        }
    }
    return arr;
}

export default function Headlines({settings}) {

    const colorScheme = useMemo(() => settings?.colorScheme ? settings?.colorScheme : 'blue', [settings])

    const [items, setItems] = useState([])
    useEffect(() => {
        GetItems();
        setInterval(() => {
            GetItems();
        }, 15 * 60 * 1000)
    }, [])

    function GetItems() {
        axios.get(`${URL.rss}/walla`, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'withCredentials': 'true', 'Access-Control-Allow-Origin': '*' } }).then(res => {
            if (res.status == 200)
                setItems(parseXML(res.data));
        }).catch((err) => console.log(err))
    }

    const Tickers = ({ index }) => {
        if (items.length == 0)
            return <p style={{ visibility: "hidden" }}>Placeholder</p>
        return (
            <p style={{ whiteSpace: "nowrap" }}>
                {items.map((item, i) => (
                    <span key={i}>
                        <span className={`${styles.time} color-scheme-${colorScheme}-headline-time`}>{moment(item.time).format("HH:mm")}</span>
                        <span className={styles.text}>{item.title}</span>
                    </span>
                ))};
            </p>
        )
    }

    return (
        <div className={`${styles.container} `}>
            <div className={styles.slider_container}>
                <div className={styles.ul}>
                    <Ticker offset="run-in" direction='toRight' speed={4}>
                        {({ index }) => <Tickers index={index} />}
                    </Ticker>
                </div >
            </div >
            <div className={styles.logo_container}>
                <img className={styles.logo} src={Logo} alt="news" />
            </div>
        </div >

    )
}
