import { useState, useEffect } from 'react'
import styles from './style.module.css'
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import CandlesPic from '../../assets/29.png'
import axios from 'axios';
import { URL } from '../../Settings';

function parseXML(data) {
    let arr = [];
    const items = data.rss.channel.item;
    for (const item of items) {
        if (item.title && item.title != '')
            arr.push({
                title: item.title,
                img: item.description.split('<br/>')[0],
                author: item.Author + ' (מעריב)',
            });
    }
    return arr;
}

export default function News({ settings, building }) {

    const [showShabbat, setShowShabbat] = useState(true)
    const [Items, setItems] = useState(null)
    const [rss, setRss] = useState([])

    function GetShabbat() {
        axios.get(`https://www.hebcal.com/shabbat?cfg=json&geo=pos&latitude=${building.Latitude != 0 ? building.Latitude : "32.109333"}&longitude=${building.Longitude != 0 ? building.Longitude : "34.855499"}&i=on&m=50&lg=h`).then(res => {
            if (res.status == 200) {
                let candles = ''
                let havdalah = ''
                let parashat = ''
                const candleIndex = res.data.items.findIndex(i => i.category === "candles");
                const havdalahIndex = res.data.items.findIndex(i => i.category === "havdalah");
                const parashatIndex = res.data.items.findIndex(i => i.category === "parashat");
                if (candleIndex !== -1 && res.data.items[candleIndex].title.length > 5)
                    candles = res.data.items[candleIndex].title.substring(res.data.items[candleIndex].title.length - 5, res.data.items[candleIndex].title.length);
                if (havdalahIndex !== -1 && res.data.items[havdalahIndex].title.length > 5)
                    havdalah = res.data.items[havdalahIndex].title.substring(res.data.items[havdalahIndex].title.length - 5, res.data.items[havdalahIndex].title.length);
                if (parashatIndex !== -1)
                    parashat = res.data.items[parashatIndex].title
                setItems({ parashat, candles, havdalah })
            }

        }).catch((err) => console.log(err))
    }
    function GetRss() {
        axios.get(`${URL.rss}/maariv`, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'withCredentials': 'true', 'Access-Control-Allow-Origin': '*' } }).then(res => {
            if (res.status == 200)
                setRss(parseXML(res.data))
        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        if (building) {
            shabbat();
            GetRss();
            setInterval(() => {
                shabbat();
            }, 360 * 60 * 1000);
            setInterval(() => {
                GetRss();
            }, 20 * 60 * 1000)
        }
    }, [building])

    function shabbat() {
        var today = new Date();
        var todayNum = today.getDay();
        const show = (todayNum == 4 || todayNum == 5 || todayNum == 6);
        if (show) {
            GetShabbat();
        }
        setShowShabbat(show);
    }
    return (
        <div className={styles.container}>
            {settings?.marketRssLeft &&
                <div className={`card ${styles.market}`}>
                    {/* <app-market-rss></app-market-rss> */}
                </div>
            }
            <div style={{ marginBottom: showShabbat && '8px' }} className={`${styles.bulletin} ${!settings?.marketRssLeft ?
                (!(settings?.shabbatLeft && showShabbat) ? styles.block_MarketRss_hidden_Heading_hidden : styles.block_MarketRss_hidden) :
                (!(settings?.shabbatLeft && showShabbat) ? styles.block_Heading_hidden : '')}`}>
                <Swiper
                    slidesPerView={'auto'}
                    slidesPerGroup={1}
                    spaceBetween={10}
                    direction={'vertical'}
                    //loop={rss.length > 10}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    className={styles.swiper}
                >
                    {rss.map((item, i) => (
                        <SwiperSlide className={styles.swiper_slides} key={i}>
                            <div className={styles.news_container}>
                                <div className={styles.news_pic_container} dangerouslySetInnerHTML={{ __html: item.img }}>
                                </div>
                                <div className={styles.text_container}>
                                    <span className={styles.headline} style={{ fontSize: settings.newsLeftMainFontSize }}>{item.title}</span>
                                    <br />
                                    <span className={styles.headline_subtitle} style={{ fontSize: settings.newsLeftSecondFontSize }}>{item.author}</span>
                                </div>
                            </div>
                        </SwiperSlide >
                    ))
                    }
                </Swiper >
            </div >
            {settings?.shabbatLeft && showShabbat &&
                <div className="card card-3 g-0" style={{ border: 0, borderRadius: 0, paddingBottom: 0 }}>
                    <div className={styles.candel_container}>
                        <div className={`card-heading ${styles.candel_heading}`}>
                            <span className='card-header-dot'></span>
                            <h3 className={styles.cendel_heading_h3} style={{ fontSize: settings.shabbatLeftMainFontSize }}>שבת <b>{Items?.parashat}</b></h3>
                        </div>
                        <div className="card-content row g-0" style={{ flexWrap: 'unset' }}>
                            <div className={styles.left_side}>
                                <ul className={styles.shabbat_ul}>
                                    <li className={styles.shabbat_li} style={{ fontSize: settings.shabbatLeftSecondFontSize }}>
                                        <span className="text">כניסת שבת</span>
                                        <span className={styles.cendel_time}><b>{Items?.candles}</b></span>
                                    </li>
                                    <li className={styles.shabbat_li} style={{ fontSize: settings.shabbatLeftSecondFontSize }}>
                                        <span >יציאת שבת</span>
                                        <span className={styles.cendel_time}><b>{Items?.havdalah}</b></span>

                                    </li>
                                </ul>
                            </div>
                            <div className={styles.right_side}>
                                <img className={styles.shabbat_img} src={CandlesPic} alt="Candles" />
                            </div>
                        </div>
                    </div >

                </div >
            }
        </div >
    )
}

