import { useEffect, useRef, useState } from 'react';
import './App.css';
import Layout from './Components/Layout';
import axios from 'axios';
import moment from 'moment';
import Settings from './Settings';
import { URL } from './Settings';
import { useParams, useNavigate } from 'react-router-dom';

// const floorNumber = 0;
// const buildingId = 31;
function App() {
  const { floorNumber, buildingId, prod } = useParams();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(Settings);
  const [data, setData] = useState(null);
  const [building, setBuilding] = useState(null);
  const [messages, setMessages] = useState([]);
  const [media, setMedia] = useState([]);
  const [ads, setAds] = useState([])
  const lastUpdateTime = useRef("")
  const floorId = useRef(0)


  useEffect(() => {
    Get();
  }, [floorNumber, buildingId])

  function Get() {
    if ((floorNumber || floorNumber == 0) && buildingId && buildingId != 0) {
      GetMessages();
      GetData();
      GetBuilding();
      GetImages();
    } else {
      const parts = window.location.href.split("/");
      if (parts.length == 7) {
        if (parts[3] === '#' && parts[4] === 'siv' && /^\d+$/.test(parts[5]) && /^-?\d+$/.test(parts[6])) {
          navigate(`/${parts[5]}/${parts[6]}`)
          return;
        }
      }
      window.open(`https://files.nuvola.co.il/NotWorking?floorid=${floorNumber}`, "_self")
    }
  }

  function GetMessages() {
    axios.get(`${URL.api}/GetMessageActive?buildingId=${buildingId}`).then(res => {
      if (res.status == 200) {
        const now = Date.now();
        setMessages([...res.data.filter((x) => moment().isAfter(x.DateStart) && moment().isBefore(x.DateEnd))])
      }
    }).catch((err) => console.log(err))
  }
  function GetData() {
    axios.get(`${URL.api}/getFloorByFloorNumber?floorNumber=${floorNumber}&buildingId=${buildingId}`).then(res => {
      if (res.status == 200 && res.data) {
        setData(res.data);
        let s = { ...settings }
        if (res.data.TypeScreen.SettingsScreen)
          s = { ...s, ...JSON.parse(res.data.TypeScreen.SettingsScreen) };
        if (res.data.SettingsScreen)
          s = { ...s, ...JSON.parse(res.data.SettingsScreen) };
        floorId.current = res.data.Id;
        lastUpdateTime.current = res.data.LastUpdateTime;
        document.body.style.zoom = s?.zoom ? `${s.zoom}%` : "90%";
        //s.colorScheme = "orange";
        setSettings(s);
        // console.log(s);
        setInterval(() => {
          GetUpdateTime();
        }, res.data.EveryMillisecondGoToServer);

      }
    }).catch((err) => console.log(err))
  }
  function GetBuilding() {
    axios.get(`${URL.api}/GetBuilding?id=${buildingId}`).then(res => {
      if (res.status == 200)
        setBuilding(res.data);
    }).catch((err) => console.log(err))
  }
  function GetImages() {
    axios.get(`${URL.api}/GetImagePresent?buildingId=${buildingId}`).then(res => {
      if (res.status == 200) {
        setMedia(res.data);
        GetAds();
      }
    }).catch((err) => console.log(err))
  }

  function GetAds() {
    axios.get(`${URL.api}/GetAdsActive?buildingId=${buildingId}`).then(res => {
      if (res.status == 200)
        setAds(res.data);
    }).catch((err) => console.log(err))
  }

  function GetUpdateTime() {
    axios.get(`${URL.management}/GetLastUpdateTimeByFloor?floorId=${floorId.current}${prod ? `&prod=${prod}` : ''}`).then(res => {
      if (res.status == 200 && res.data)
        if (lastUpdateTime.current !== res.data) {
          window.location.reload();
        }
    }).catch((err) => console.log(err))
  }
  if ((floorNumber || floorNumber == 0) && buildingId && buildingId != 0) {
    return <Layout settings={settings} data={data} messages={messages} building={building} media={media} ads={ads} floorId={floorId} />

  }
  return (
    <></>
  );
}

export default App;
